import React from "react"
import styled from 'styled-components'
import media from "../../styles/media";

const PrivacyHeroCon = styled.div`
  height: 550px;
  width: 100vw;

  .author {
    position: absolute;
    top: 400px;
    left: 20px;
    text-transform: uppercase;
    color: white;
  }
  .title {
    width: 80vw;
    color: var(--light-green);
    position: absolute;
    left: 20px;
    top: 250px;
  }

  ${media.laptop`
    height: 95vh;

    .date {
      position: absolute;
      top: 60vh;
      left: 450px;
    }
    .author {
      position: absolute;
      top: 60vh;
      left: 60px;
    }

    .title {
      width: 550px;
      left: 60px;
      position: absolute;
      top: 35vh;
    }
  `}
`

function PrivacyHero(info) {

  return (
    <PrivacyHeroCon>
      <p className="title blurbText">Data (Personal Information) Privacy Policy</p>
      <p className="author subHeading">Effective date: 1 january 2021</p>
    </PrivacyHeroCon>
  );
}
export default PrivacyHero;