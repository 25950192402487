import './App.css';
import './styles/themes.css';
import React, { useEffect, useState } from "react";
import "./index.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax'

import Home from './components/Home'
import About from './components/About'
import Services from './components/Services'
import Work from './components/Work'
import Blog from './components/Blog'
import Contact from "./components/Contact"
import Header from "./components/header"
import Post from './components/Post'
import CaseStudy from './components/CaseStudy'
import Loader from './components/loader';
import Background from './components/background';
import Page404 from './components/page404';
import Privacy from './components/Privacy';

function App() {

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() { 
      setIsLoaded(true)
    }, 5000)
  })

  return (
    <Router>
      <ParallaxProvider>
        <Loader loaded={isLoaded} />
        <Header />
        <Background />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/work" exact component={Work} />
          <Route path="/work/:slug" component={CaseStudy} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/blog/:slug" component={Post} />
          <Route path="/services" exact component={Services} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="*" component={Page404} />
        </Switch>
      </ParallaxProvider>
    </Router>
  );
}

export default App;
