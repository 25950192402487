import React, { useRef, useState } from "react"
import styled from 'styled-components'

import Home1 from '../../images/home1.jpg'
import Home2 from '../../images/home2.jpg'
import Home3 from '../../images/home3.jpg'
import HomeOne from '../../images/homeOne.jpeg'
import HomeTwo from '../../images/homeTwo.jpeg'
import HomeThree from '../../images/homeThree.jpeg'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import media from "../../styles/media"
import Icon from '../../images/icon.png'
import Fb from '../../images/fb.svg'
import Li from '../../images/li.svg'

const HomeCon = styled.div`
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-sizing: border-box;
  outline: none;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  .info {
    position: absolute;
    z-index: 2;
    top: calc(var(--vh, 1vh) * 20);
    margin: 0;
    padding: 0 var(--gutter-mobile-s);
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: normal;
      margin: 0;
      letter-spacing: -0.04em;
      &.blue {
        color: var(--dark-blue);
      }
      &.green {
        color: var(--dark-green);
      }
      &.red {
        color: var(--dark-red);
      }
    }
    .social {
      display: none; 
    }
  }

  .swiper-container {
    height: calc(var(--vh, 1vh) * 100);
    .swiper-wrapper {
      height: calc(var(--vh, 1vh) * 100);
      .swiper-slide {
        height: calc(var(--vh, 1vh) * 100);
        .image {
          z-index: -2;
          height: calc(var(--vh, 1vh) * 100);
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .icon {
    display: none;
    z-index: auto;
  }
  
  user-select: none;
  -webkit-user-drag: none;
  #main {
    display: flex;

    .part {
      flex: 1;

      .section {
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;

        img {
          width: 100vw;
          height: 100vh;
          object-fit: cover;
          position: absolute;
          left: var(--x);
          pointer-events: none;
        }
      }
    }
  }

  .social {
    display: none;
  }

  ${media.laptop`
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    .title {
      font-size: 90px;
      line-height: 80px;
      font-weight: normal;
      letter-spacing: -3px;

      .line-wrap {
        overflow: hidden;
        height:95px;
      }

      &.blue {
        color: var(--dark-blue);
      }
      &.green {
        color: var(--dark-green);
      }
      &.red {
        color: var(--dark-red);
      }

      p {
        margin: 0;
        position: absolute;
        z-index: 9;
        left: 30px;
        top: 32vh;
        max-width: 48vw;
        transition: opacity 0.3s linear;
      }
      .showing {
        transition: all .3s linear;
        &.blue {
          color: var(--dark-blue);
        }
        &.red {
          color: var(--dark-green);
        }
        &.green {
          color: var(--dark-red);
        }
        .line-wrap {
          .line {
            transition: all .5s linear;
            transition-delay: .5s;
            transform: translateY(0px);
          }
        }
      }
      .hidden {
        &.blue {
          color: var(--dark-blue);
        }
        &.red {
          color: var(--dark-green);
        }
        &.green {
          color: var(--dark-red);
        }
        .line-wrap {
          .line {
            transform: translateY(90px);
          }
        }
      }
    }
    .icon {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      img {
        width: 25vw;
        height: auto;
        height: calc(var(--vh, 1vh) * 98);
      }
    }

    .social {
      display: flex;
      position: absolute;
      bottom: 45px;
      left: 0px;

      img {
        margin-left: 40px;
      }
    }

    .dotstyle {
      position: absolute;
      right: 40px;
      bottom: 40px;
      ul {
        display: flex;
      }
    }

    .dotstyle ul {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      list-style: none;
      cursor: default;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .dotstyle li {
      position: relative;
      display: block;
      float: left;
      margin: 0 16px;
      width: 10px;
      height: 12px;
      cursor: pointer;
    }

    .dotstyle li button {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      border-radius: 50%;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.3);
      text-indent: -999em; /* make the text accessible to screen readers */
      cursor: pointer;
      position: absolute;
    }

    .dotstyle li button:focus {
      outline: none;
    }

    .dotstyle-fillin li button {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px white;
      -webkit-transition: box-shadow 0.3s ease;
      transition: box-shadow 0.3s ease;
    }

    .dotstyle-fillin li button:hover,
    .dotstyle-fillin li button:focus {
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.6)
    }

    .dotstyle-fillin li.current button {
      box-shadow: inset 0 0 0 8px white;
    }


    .wrapper{
      width: 100vw;
      height: 300vh;
      margin: 0 auto;
      display: flex;
      flex-flow: row nowrap;
      box-sizing: content-box;
    }
    .mask{
      height: 100vh;
      overflow: hidden;
    }
    .column{
      display: flex;
      flex-flow: column nowrap;
      transition: all 0.5s ease-in;
      position: relative;
    }
    .right.column{
      flex-direction: column-reverse;
    }
    .left{
      width: 50%;
    }
    .right{
      width: 50%;
      transform: translateY(-200vh);

    }
    .content{
      display: flex;
      flex-flow: column nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
    .content h2{
      color: white;
      font-weight: 600;
      font-size: 50px;
    }
    .content p{
      font-size: 18px;
      color: white;
    }
    .content a{
      background-color: white;
      padding: 10px 30px;
      border-radius: 25px;
      text-decoration: none;
    }
    .first{
      background-color: var(--light-blue);
    }
    .first a{
      color: var(--dark-blue);
    }
    .second{
      background-color: var(--light-green);
    }
    .second a{
      color: var(--dark-green);
    }
    .third{
      background-color: var(--light-red);
    }
    .third a{
      color: var(--dark-red);
    }
    .pic{
      width: 100%;
      height: 100%;
      background-size: cover;
    }
    .right .first{
      background-image: url(${HomeOne});
    }
    .right .second{
      background-image: url(${HomeTwo});
    }
    .right .third{
      background-image: url(${HomeThree});
    }

  `}
`

function Home() {
  const [activeSlide, setActiveSlide] = useState(0)

  SwiperCore.use([Autoplay]);

  let width = window.innerWidth;

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const leftRef = useRef(null)
  const rightRef = useRef(null)
  const [ currentColor, setCurrentColor ] = useState('blue')

  window.addEventListener('keyup', (e)=>{
    if(e.key == 'ArrowUp'){//up
      prev();
    }
    if(e.key == 'ArrowDown'){//down
      next();
    }
  });

  document.addEventListener('mousewheel', scroll);
  document.addEventListener('DOMMouseScroll', scroll);

  var w;
  function scroll(e){
    clearTimeout(w);
    var e = window.event || e;
    w = setTimeout(() => {
      w = undefined;
      if(e.wheelDelta < 0 || e.detail > 0){
          next();
      }
      if (e.wheelDelta > 0  || e.detail < 0){
          prev();
      } 
    }, 50);
  }

  function next(){
    let r,l;

    switch(currentColor){
      case 'green':{//to show blue and start over
        l='0';
        r='-200';
        setCurrentColor('blue')
        
        break;
      }
      case 'blue':{ //to show red
        l= '-100';
        r= '-100';
        setCurrentColor('red')
        break;
      }
      case 'red':{//to show green
        l='-200';
        r='0';
        setCurrentColor('green')
        break;
      }
    }
    
    if (leftRef.current && rightRef.current) {
      leftRef.current.style.transform = `translateY(${l}vh)`;
      rightRef.current.style.transform = `translateY(${r}vh)`;
    }

  }

  function prev(){
    let r,l;

    switch(currentColor){
      case 'red':{//to show blue and start over
        l='0';
        r='-200';
        setCurrentColor('blue')
        break;
      }
      case 'blue':{ //to show green
        l= '-200';
        r= '0';
        setCurrentColor('green')
        break;
      }
      case 'green':{//to show red
        l='-100';
        r='-100';
        setCurrentColor('red')
        break;
      }
    }
    
    if (leftRef.current && rightRef.current) {
      leftRef.current.style.transform = `translateY(${l}vh)`;
      rightRef.current.style.transform = `translateY(${r}vh)`;
    }
  }


  const goToBlue = () => {
    let r,l;

    switch(currentColor){
      case 'red':{//to show blue and start over
        l='0';
        r='-200';
        setCurrentColor('blue')
        break;
      }
      case 'blue':{ //to show green
        l= '0';
        r= '-200';
        setCurrentColor('blue')
        break;
      }
      case 'green':{//to show red
        l='0';
        r='-200';
        setCurrentColor('blue')
        break;
      }
    }
    
    if (leftRef.current && rightRef.current) {
      leftRef.current.style.transform = `translateY(${l}vh)`;
      rightRef.current.style.transform = `translateY(${r}vh)`;
    }
  }

  const goToGreen = () => {
    let r,l;

    switch(currentColor){
      case 'red':{//to show blue and start over
        l='-100';
        r='-100';
        setCurrentColor('red')
        break;
      }
      case 'blue':{ //to show green
        l= '-100';
        r= '-100';
        setCurrentColor('red')
        break;
      }
      case 'green':{//to show red
        l='-100';
        r='-100';
        setCurrentColor('red')
        break;
      }
    }
    
    if (leftRef.current && rightRef.current) {
      leftRef.current.style.transform = `translateY(${l}vh)`;
      rightRef.current.style.transform = `translateY(${r}vh)`;
    }
  }

  const goToRed = () => {
    let r,l;

    switch(currentColor){
      case 'red':{//to show blue and start over
        l='-200';
        r='0';
        setCurrentColor('green')
        break;
      }
      case 'blue':{ //to show green
        l= '-200';
        r= '0';
        setCurrentColor('green')
        break;
      }
      case 'green':{//to show red
        l='-200';
        r='0';
        setCurrentColor('green')
        break;
      }
    }
    
    if (leftRef.current && rightRef.current) {
      leftRef.current.style.transform = `translateY(${l}vh)`;
      rightRef.current.style.transform = `translateY(${r}vh)`;
    }
  }

  setTimeout(() => {
    next()
  }, 45000);
  
  var titles = [
    {
      title1: 'We’re a B2B ',
      title2: 'Marketing ',
      title3: 'Agency',
      title: 'We’re a B2B Marketing Agency',
      color: 'blue'
    },
    {
      title1: 'We’re',
      title2: 'solutionists at',
      title3: 'heart',
      title: 'We’re solutionists at heart',
      color: 'green'
    },
    {
      title1: 'We deliver',
      title2: 'results that spark ',
      title3: 'connections',
      title: 'We deliver results that spark connections',
      color: 'red'
    }
  ]

  return (
    <>
    <HomeCon>
      {width > 768 && (
        <>
          <div class="mask">
            <div class="wrapper">
              <div class="left column" ref={leftRef}>
                <div class="content first">
                </div>

                <div class="content second">
                </div>

                <div class="content third">
                </div>
              </div>
              <div class="right column" ref={rightRef}>
                <div class="pic first"></div>
                <div class="pic second"></div>
                <div class="pic third"></div>
              </div>
            </div>
          </div>
          <div id="main">
            <div className='title'>
              <p className={currentColor === 'blue' ? 'showing blue' : 'hidden blue'}>
                <div className='line-wrap'>
                  <div className='line line2'>
                    {titles[0].title1}
                  </div>
                </div>
                <div className='line-wrap'>
                  <div className='line line3'>
                    {titles[0].title2}
                  </div>
                </div>
                <div className='line-wrap'>
                  <div className='line line4'>
                    {titles[0].title3}
                  </div>
                </div>
              </p>
              <p className={currentColor === 'red' ? 'showing red' : 'hidden red'}>
                <div className='line-wrap'>
                  <div className='line line5'>
                    {titles[1].title1}
                  </div>
                </div>
                <div className='line-wrap'>
                  <div className='line line6'>
                    {titles[1].title2}
                  </div>
                </div>
                <div className='line-wrap'>
                  <div className='line line7'>
                    {titles[1].title3}
                  </div>
                </div>
              </p>
              <p className={currentColor === 'green' ? 'showing green' : 'hidden  green'}>
                <div className='line-wrap'>
                  <div className='line line8'>
                    {titles[2].title1}
                  </div>
                </div>
                <div className='line-wrap'>
                  <div className='line line9'>
                    {titles[2].title2}
                  </div>
                </div>
                <div className='line-wrap'>
                  <div className='line line10'>
                    {titles[2].title3}
                  </div>
                </div>
              </p>
            </div>
          </div>
          <div className="icon">
            <img src={Icon} alt="logo" />
          </div>
          <div class="dotstyle dotstyle-fillin">
            <ul>
              <li onClick={goToBlue} className={currentColor ===  'blue' ? 'current' : ''}><button>About</button></li>
              <li onClick={goToGreen} className={currentColor ===  'red' ? 'current' : ''}><button>Products</button></li>
              <li onClick={goToRed} className={currentColor ===  'green' ? 'current' : ''}><button>Portfolio</button></li>
            </ul>
          </div>
          <div className="social">
            <a href="https://www.facebook.com/TheCatalystAfrica/" target="_blank" rel="noreferrer"><img src={Fb} alt="fb" /></a>
            <a href="https://www.linkedin.com/company/catalyst-africa/" target="_blank" rel="noreferrer"><img src={Li} alt="li" /></a>
          </div>
        </>
      )}
      {width < 768 && (
        <>
          <div className="info">
            <h2 className={titles[activeSlide].color}>{titles[activeSlide].title}</h2>
          </div>
         <Swiper
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 5000 }}
            onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
          > 
            <SwiperSlide>
              <div className="image">
                <img src={Home1} alt="bg" />
              </div>
              <div className="icon">
                <img src={Icon} alt="logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image">
                <img src={Home2} alt="bg" />
              </div>
              <div className="icon">
                <img src={Icon} alt="logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image">
                <img src={Home3} alt="bg" />
              </div>
              <div className="icon">
                <img src={Icon} alt="logo" />
              </div>
            </SwiperSlide>
          </Swiper>
        </>
      )}
    </HomeCon>
    {/* </div> */}
    </>
  );
}

export default Home;