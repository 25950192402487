import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import media from "../../styles/media";
import Footer from "../footer";
import TemplateHero from "./templateHero";
import { useLocation } from 'react-router-dom'
import { prismicClient } from '../client';
import Prismic from '@prismicio/client'

const PostCon = styled.div`
  margin: 150px 20px 150px;
  h2 {
    margin: 50px 0 30px;
  }
  span {
    color: var(--light-red);
    font-weight: bold;
  }

  .heading2 {
    font-size: 30px;
    line-height: 36px;
  }
  ${media.laptop`
    margin: 100px 350px 200px;

    .heading2 {
      font-size: 36px;
      line-height: 36px;
    }
  `}
`

function Post() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  let location = useLocation()

  const [doc, setDocData] = useState(null)
  const [currentBlog, setCurrentBlog] = useState(null)

  useEffect(() => {
    // if (!location.state) {
      const fetchData = async () => {
        const response = await prismicClient.query(
          Prismic.Predicates.at('document.type', 'blogpost')
        )
        if (response) {
          setDocData(response.results)
        }
      }
      fetchData()
    // }
  }, [])

  var slug = location.pathname.replace("/blog/","")

  if(doc) {
    var newArray = doc.filter(function (el) {
      return el.data.slug === slug
    });
  }

  useEffect(() => {
  if (location.state) {
    setCurrentBlog(location.state)
  } else if (doc) {
    setCurrentBlog(newArray[0])
  }
}, [doc])
  
  return (
    <>
    {currentBlog && (
      <>
      <TemplateHero info={currentBlog} />
      <PostCon>
        
        {currentBlog.data.introduction.map((item, index) => {return(<p key={index} className={item.type}>{item.text}</p>)})}

        <br/><br/>

        {currentBlog.data.blogcontent.map((item, index) => {return(<p key={index} className={item.type}>{item.text}</p>)})}

      </PostCon>
      <Footer color="grey" />
      </>
    )}
    </>
  );
}
export default Post;