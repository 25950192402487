import React, { useEffect } from "react"
import styled from 'styled-components'
import media from "../../styles/media";
import Footer from "../footer";

const InfoCon = styled.div`
  margin: 150px 20px 150px;
  color: #919191;
  h2 {
    margin: 50px 0 30px;
  }
  span {
    color: black;
  }
  a {
    color: #919191;
    text-decoration: none;
  }

  .heading2 {
    font-size: 30px;
    line-height: 36px;
  }

  .note {
    border: 1px solid black;
    font-weight: bold;
    color: black;
    padding: 20px 15px;
  }

  table {
    width: 100%;
    tr {
      td {
        width: 50%;
        padding-bottom: 20px;
        vertical-align: top;
        &.top {
          font-weight: bold;
          color: black;
        }
        ol {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  ${media.laptop`
    margin: 100px 350px 200px;

    span {
      font-size: 24px;
      line-height: 36px;
    }

    .heading2 {
      font-size: 36px;
      line-height: 36px;
    }
  `}
`

function Info() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <InfoCon>
      <span>Introduction</span>
      <p>
        The Protection of Personal Information Act No 4 of 2013 (“POPI”) was gazetted on 26 November 2013.  While the portion of POPI in terms whereof the Information Regulator was established commenced on 11 April 2014, the commencement date of the rest of POPI is 1 July 2020.  All data users (referred to in POPI as “responsible parties”) are obliged to become compliant with POPI by 1 July 2021. 
        <br/><br/>
        POPI is South Africa’s equivalent of the European Union’s General Data Protection Regulation (“GDPR”), which became effective on 25 May 2018.  The GDPR requires compliance by any business processing data in the EU, or processing data of persons residing in the EU.
        <br/><br/>
        This Data (Personal Information) Privacy Policy governs the manner in which the Company safeguards personal information when processing it within the context of balancing the right to privacy against other rights (particularly the right of access to information), and protecting important interests, including the free flow of information within South Africa and internationally.
        <br/><br/>
        The Company complies with the requirements of the GDPR (insofar as it is necessary for the Company to do so in the course of its business) and of POPI.
      </p>

      <br/><br/>

      <span>Definitions</span>
      <p>The following terms bear the following meanings in this Policy document. As far as possible, the definitions mirror those in POPI:</p>
      <br/><br/>
      <table>
        <tbody>
          <tr>
            <td className="top">data subject</td>
            <td>the person to whom personal information relates</td>
          </tr>
          <tr>
            <td className="top">information officer</td>
            <td>in relation to the Company, the chief executive officer or equivalent officer, or any person duly authorised by that officer, or any person acting in the capacity of information officer, or any person authorised by such person</td>
          </tr>
          <tr>
            <td className="top">information officer</td>
            <td>in relation to the Company, the chief executive officer or equivalent officer, or any person duly authorised by that officer, or any person acting in the capacity of information officer, or any person authorised by such person</td>
          </tr>
          <tr>
            <td className="top">operator</td>
            <td>a person who processes personal information for a responsible party in terms of a contract or mandate, without coming under the direct authority of that party</td>
          </tr>
          <tr>
            <td className="top">PAIA</td>
            <td>the Promotion of Access to Information Act, 2 of 2000, as amended</td>
          </tr>
          <tr>
            <td className="top">personal information</td>
            <td>
              information relating to an identifiable, living, natural person or juristic person, including but not limited to:
              <ol>
                <li>information relating to the race, gender, sex, pregnancy, marital status, national, ethnic or social origin, colour, sexual orientation, age, physical or mental health, well-being, disability, religion, conscience, belief, culture, language and birth of the person;</li>
                <li>information relating to the education or the medical, financial, criminal or employment history of the person;</li>
                <li>any identifying number, symbol, email address, physical address, telephone number, location information, online identifier or other particular assignment to the person;</li>
                <li>the biometric information of the person;</li>
                <li>the personal opinion, views or preferences of the person;</li>
                <li>correspondence sent by the person that is implicitly or explicitly of a private or confidential nature or further correspondence that would reveal the contents of the original correspondence;</li>
                <li>the views or opinions of another individual about the person; and</li>
                <li>the name of the person if it appears with other personal information relating to the person or if the disclosure of the name itself would reveal information about the person</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td className="top">processing</td>
            <td>
              any operation or activity or any set of operations, whether or not by automatic means, concerning personal information, including:
              <ol>
                <li>the collection, receipt, recording, organisation, collation, storage, updating or modification, retrieval, alteration, consultation or use;</li>
                <li>dissemination by means of transmission, distribution or making available in any other forms; or</li>
                <li>merging, linking, as well as restriction, degradation, erasure, or destruction of information</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td className="top">responsible party</td>
            <td>a public or private body or any other person which, alone or in conjunction with others, determines the purpose of and means for processing personal information</td>
          </tr>
          <tr>
            <td className="top">special personal information</td>
            <td>
              personal information concerning:
              <ol>
                <li>the religious or philosophical beliefs, race or ethnic origin, trade union membership, political persuasion, health or sex life, or biometric information of a data subject; or</li>
                <li>the criminal behaviour of a data subject if such information relates to the alleged commission by a data subject of any offence, or to any proceedings in respect of any offence allegedly committed by a data subject or the disposal of such proceedings</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
      <br/><br/>

      <span>Why does the company process personal information?</span>
      <p>
        <u>The Relevant personal information</u>
        <br/><br/>
        The Company will process only personal information that is relevant to the Services.
        <br/><br/>
        While the Company has no control over the accuracy and completeness of the personal information given to it by its clients, where the Company is required to collect personal data, it processes the personal information in a manner so as to ensure that such personal information: 
        <br/>
        <ul>
          <li>is accurate, complete and up-to-date;</li>
          <li>has been obtained by lawful and fair means, and where appropriate, with the knowledge and/or consent of the data subject;</li>
          <li>is collected for a particular purpose, which purpose is specified at the time of collection;</li>
          <li>is relevant for the purpose for which it is to be used;</li>
          <li>is not used for purposes outside of its original intended and specified purpose (except with the consent of the data subject or the authority of the law);</li>
        </ul>
        <br/><br/>
        <u>Special personal information</u>
        <br/><br/>
        The Company does not process special personal information unless:
        <br/><br/>
        <ul>
          <li>the data subject has consented thereto;</li>
          <li>processing of the special personal information is necessary for the establishment, exercise or defence of a right or obligation in law;</li>
          <li>processing of the special personal information is necessary to comply with an obligation of international public law;</li>
          <li>processing of the special personal information is for historical, statistical or research purposes to the extent that the purpose serves a public interest and the processing is necessary for the purpose concerned, or it appears to be impossible or would involve a disproportionate effort to ask for consent, and sufficient guarantees are provided to ensure that the processing does not adversely affect the individual privacy of the data subject to a disproportionate extent;</li>
          <li>the information has deliberately been made public by the data subject; or</li>
          <li>the provisions of sections 28 to 33 of POPI, as the case may be, are complied with. </li>
        </ul>
        <br/><br/>
        <u>Personal information concerning children</u>
        <br/><br/>
        The Company does not process personal information concerning children unless such processing is:
        <br/><br/>
        <ul>
          <li>carried out with the prior consent of a competent person;</li>
          <li>necessary for the establishment, exercise or defence of a right or obligation in law;</li>
          <li>necessary to comply with an obligation of international public law;</li>
          <li>for historical, statistical or research purposes to the extent that the purpose serves a public interest and the processing is necessary for the purpose concerned, or it appears to be impossible or would involve a disproportionate effort to ask for consent, and sufficient guarantees are provided to ensure that the processing does not adversely affect the individual privacy of the data subject to a disproportionate extent;</li>
          <li>the information has deliberately been made public by the child with the consent of a competent person</li>
        </ul>
        <br/><br/>
      </p>

      <span>How does the company safeguard the personal information it processes?</span>
      <p>
        <u>Retention of personal information</u>
        <br/><br/>
        Any personal information processed by the Company is retained only:
        <br/><br/>
        <ul>
          <li>for so long as is necessary for the purpose for which it was processed; </li>
          <li>to the extent that the law requires the Company to retain such personal information;</li>
          <li>to the extent that the Company requires the retention of the personal information to resolve disputes and/or to enforce its legal agreements and policies.</li>
        </ul>
        <br/><br/>
        The Company will take all the steps reasonably necessary to ensure that the personal information is received, recorded, organised, collated, stored, updated, modified, retrieved, altered, consulted and/or used securely and in a manner that complies with the requirements of POPI.
        <br/><br/>
        <u>Transfer and/or disclosure of personal information</u>
        <br/><br/>
        Personal information processed by the Company may be transferred to computers located in South Africa, or to computers located outside of South Africa, where the laws applicable to the protection of personal information may differ from those in South Africa.
        <br/><br/>
        The Company may transfer and/or disclose personal information processed by it:
        <br/><br/>
        <ul>
          <li>to its clients, if the personal information was initially provided to the Company by such client, or if the personal information has been collected for use by the client;</li>
          <li>to fulfil the purpose for which the personal information was processed;</li>
          <li>to its contractors, service providers and any third party utilised by the Company to render the Services;</li>
          <li>for any purpose disclosed by the Company when the personal information was collected by the Company;</li>
          <li>if it is required to do so by law or in response to valid requests by public bodies;</li>
          <li>if it is involved in any business transaction in terms whereof its business is sold, acquired or involved in a merger with another entity;</li>
          <li>if the Company reasonably believes that such disclosure and/or transfer is necessary or appropriate to protect its rights, or the rights of its clients;</li>
          <li>for any reason for which the data subject has given his/her/its consent.</li>
        </ul>
        <br/><br/>
        The Company will take all the steps reasonably necessary to ensure that the personal information is disseminated, whether by means of transmission, distribution or making available in any other forms, securely and in a manner that complies with the requirements of POPI.
        <br/><br/>
        <u>Security of personal information</u>
        <br/><br/>
        The Company will take appropriate, reasonable technical and organisational measures to prevent the loss of, damage to or unauthorised destruction of personal information, and unlawful access to or processing of personal information. 
        <br/><br/>
        Where the Company is the responsible party, the Company shall have due regard to generally accepted information security practices and procedures which apply to it generally, or which are required in terms of the industry in which the Company operates.
      </p>
<br/>
<br/>
      <span>What rights do data subjects have in respect of their personal information?</span>
      <p>
        <u>Openness</u>
        <br/><br/>
        Subject to the exceptions provided in section 18(3) and (4) of POPI, if the Company collects personal information, the Company will take all reasonably practicable steps to ensure that data subjects are aware of:
        <br/><br/>
        <ul>
          <li>the information being collected, or where the information is not collected from the data subject, the source from which it is collected;</li>
          <li>the name and address of the responsible party;</li>
          <li>the purpose for which the information is being collected;</li>
          <li>whether or not the supply of the information by that data subject is voluntary or mandatory;</li>
          <li>the consequences of failure to provide the information;</li>
          <li>any particular law authorising or requiring the collection of information;</li>
          <li>the fact that, where applicable, the responsible party intends to transfer the information to another country or international organisation, and the level of protection afforded to the information by that country or international organisation;</li>
          <li>the recipient or category of recipients of the information;</li>
          <li>the nature or category of the information;</li>
          <li>the existence of the right of access to and right to rectify the information collected;</li>
          <li>the existence of the right to object to the processing of personal information as per section 11(3) of POPI;</li>
          <li>the right to lodge a complaint to the Information Regulator.</li>
        </ul>
        <br/><br/>
        If the Company is the responsible party, a data subject, having provided adequate proof of identity, has the right to:
        <br/><br/>
        <ul>
          <li>request the Company to confirm, free of charge, whether or not it holds his/her/its personal information; and</li>
          <li>request from the Company the record or a description of his/her/its personal information held by the Company, including information about the identity of all third parties, or categories of third parties, who have, or have had, access to the information</li>
        </ul>
        <br/><br/>
        <u>The right to request personal information to be corrected</u>
        <br/><br/>
        A data subject has the right to request the correction of personal information and may, in the prescribed manner and for a reasonable fee, request the Company to:
        <br/><br/>
        <ul>
          <li>correct or delete personal information about the data subject in its possession or under its control that is inaccurate, irrelevant, excessive, out of date, incomplete, misleading or obtained unlawfully; or</li>
          <li>destroy or delete a record of personal information about the data subject that the Company is no longer authorised to retain.</li>
        </ul>
        <br/><br/>
        On receipt of a request as aforesaid, the Company must, as soon as reasonably practicable:
        correct the information;
        <br/><br/>
        <ul>
          <li>destroy or delete the information;</li>
          <li>provide the data subject, to his or her satisfaction, with credible evidence in support of the information; or</li>
          <li>where agreement cannot be reached between the Company and the data subject, and if the data subject so requests, take such steps as are reasonable in the circumstances, to attach to the information in such a manner that it will always be read with the information, an indication that a correction of the information has been requested but has not been made.</li>
        </ul>
        <br/><br/>
        If the Company has taken steps that result in a change to the information and the changed information has an impact on decisions that have been or will be taken in respect of the data subject in question, the Company must, if reasonably practicable, inform each person or body or responsible party to whom the personal information has been disclosed of those steps.
        <br/><br/>
        The Company will notify a data subject, who has made a request as aforesaid, of the action taken as a result of the request.
        <br/><br/>
        Sections 18 and 53 of PAIA apply to requests for access to personal information.
        <br/><br/>
        The Company will refuse to disclose any information requested to which the grounds for refusal of access to records set out in the applicable sections of PAIA apply.
      </p>
      <br/>
      <br/>

      <span>Supervisory Authorities</span>
      <p>
        The supervisory authority in South Africa is the Information Regulator with contact details as follows: 
        <br/><br/>
        <b>Email:</b> <a href="mailto:inforeg@justice.gov.za" target="_blank" rel="noreferrer">inforeg@justice.gov.za</a><br/> 		
        <b>Tel:</b> <a href="tel:+27124064818" target="_blank" rel="noreferrer">+27 12 406 4818</a>		Fax : (086) 500 3351<br/>
        <b>Physical address:</b>  SALU Building, 316 Thabo Sehume Street, Pretoria
        <br/><br/>
        The supervisory authority for the European Union is the European Data Protection Board with contact details as follows:
        <br/><br/>
        <b>Email:</b> <a href="mailto:edpb@edpb.europa.eu" target="_blank" rel="noreferrer">edpb@edpb.europa.eu</a><br/>
        <b>Postal Address:</b> Rue Wiertz 60, B-1047 Brussels<br/>
        <b>Physical address:</b> Rue Montoyer 30, B-1000 Brussels
      </p>
      <br/>
      <br/>

      <div className="note">
        The Company’s business does not require prior authorisation from any supervisory authority in respect of data protection.
      </div>
      <br/>
      <br/>

      <span>Information officer (RSA) / Data Protection (EU)</span>
      <p>
        The Company’s designated information/data protection officer is Matthew Nkala, with contact details as follows : 
        <br/><br/>
        <b>Email:</b> <a href="mailto:matt@thecatalyst.africa" target="_blank" rel="noreferrer">matt@thecatalyst.africa</a>	<br/>	
        <b>Tel:</b><a href="tel:+27722232460" target="_blank" rel="noreferrer">+27 (0) 72 223 2460</a><br/>
        <b>Physical address:</b>	B14, The Woodlands Office Park, 20 Woodlands drive, Woodmead 2191, South Africa
      </p>
    </InfoCon>
    <Footer color="grey" />
    </>
  );
}
export default Info;