import React, { useEffect, useRef } from "react";

import AboutHero from './hero'
import Gallery from './gallery'
import Gallery2 from './gallery2'
import B2B from './b2b'
import Values from './values'
import Text from './text'
import Process from './process'

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // page transition
  let screen = useRef(null);
  let body = useRef(null);

  return (
    <>
      <div className="load-container">
        <div className="load-screen red" ref={screen}>
        </div>
      </div>
      <div data-barba="container" className="Home" ref={body}>
        <AboutHero />
        <Gallery />
        <B2B />
        <Values />
        <Text />
        <Process />
        <Gallery2 />
      </div>
    </>
  );
}
export default About;