import React from "react"
import styled from 'styled-components'
import media from "../../styles/media";

const TemplateHeroCon = styled.div`
  height: 550px;
  width: 100vw;

  .date {
    position: absolute;
    top: 190px;
    left: 20px;
    color: var(--dark-green);
  }
  .author {
    position: absolute;
    top: 400px;
    left: 20px;
    color: var(--dark-green);
    text-transform: uppercase;
  }
  .title {
    color: white;
    width: 80vw;
    position: absolute;
    left: 20px;
    top: 250px;
  }

  ${media.laptop`
    height: 95vh;

    .date {
      position: absolute;
      top: 60vh;
      left: 450px;
    }
    .author {
      position: absolute;
      top: 60vh;
      left: 60px;
    }

    .title {
      color: white;
      width: 55vw;
      left: 60px;
      position: absolute;
      top: 35vh;
    }
  `}
`

function TemplateHero(info) {
  return (
    <TemplateHeroCon>
      <p className="date subHeading">{info.info.data.date[0].text}</p>
      <p className="title blurbText">{info.info.data.title[0].text}</p>
      <p className="author subHeading">{info.info.data.author[0].text}</p>
    </TemplateHeroCon>
  );
}
export default TemplateHero;