import React, { useEffect, useRef } from "react";

import WorkHero from './hero'
import Campaigns from './campaigns'

function Work() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // page transition
  let screen = useRef(null);
  let body = useRef(null);
  
  return (
    <>
      <div className="load-container">
        <div className="load-screen blue" ref={screen}>
        </div>
      </div>
      <div data-barba="container" className="Home" ref={body} >
        <WorkHero />
        <Campaigns />
      </div>
    </>
  );
}
export default Work;
