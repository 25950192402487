import React from "react";
import styled, { keyframes } from 'styled-components'
import media from "../styles/media";

import Logo from '../images/logo.png'

const RedSlide = keyframes`
  0% {
    left: 0vw;
  }
  50% {
    left: -100vw;
  }
  100% {
    left: -200vw;
  }
`
const GreenSlide = keyframes`
  0% {
    left: 100vw;
  }
  50% {
    left: 0vw;
  }
  100% {
    left: -100vw;
  }
`
const BlueSlide = keyframes`
  0% {
    left: 200vw;
  }
  50% {
    left: 100vw;
  }
  100% {
    left: 0vw;
  }
`
const PreloaderMove = keyframes`
  0% {
    left: 0vw;
  }
  100% {
    left: -100vw;
  }
`
const RedSlideMob = keyframes`
  0% {
    top: 0vw;
  }
  50% {
    top: -100vh;
  }
  100% {
    top: -200vh;
  }
`
const GreenSlideMob = keyframes`
  0% {
    top: 100vh;
  }
  50% {
    top: 0vh;
  }
  100% {
    top: -100vh;
  }
`
const BlueSlideMob = keyframes`
  0% {
    top: 200vh;
  }
  50% {
    top: 100vh;
  }
  100% {
    top: -20vh;
  }
`
const PreloaderMoveMob = keyframes`
  0% {
    top: 0vh;
  }
  100% {
    top: -100vh;
  }
`
const LogoMove = keyframes`
  0% {
    top: calc(var(--vh, 1vh) * 49);
    left: 40vw;
  }
  100% {
    top: 55px;
    left:40px;
  }
`
const LogoMoveMob = keyframes`
0% {
  top: calc(var(--vh, 1vh) * 49);
  left: 25vw;
}
100% {
  top: 20px;
  left:20px;
}
`

const LoaderCon = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  animation: ${PreloaderMoveMob} 2s ease-in-out forwards;
  animation-delay: 3.5s;

  img  {
    position: fixed;
    height: 28px;
    top: calc(var(--vh, 1vh) * 49);
    left: 25vw;
    animation: ${LogoMoveMob} 1.5s ease-in-out forwards;
    animation-delay: 3.25s;
  }

  .red {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 120);
    margin: 0;
    background: var(--light-red);
    animation: ${RedSlideMob} 4s ease-in-out forwards;
  }

  .green {
    position: absolute;
    left: 0;
    top: 100vw;
    width: 100vw;
    margin: 0;
    height: calc(var(--vh, 1vh) * 120);
    background: var(--light-green);
    animation: ${GreenSlideMob} 4s ease-in-out forwards;
  }

  .blue {
    position: absolute;
    left: 0;
    top: 200vw;
    width: 100vw;
    margin: 0;
    height: calc(var(--vh, 1vh) * 120);
    background: var(--light-blue);
    animation: ${BlueSlideMob} 4s ease-in-out forwards;
  }

  &.disable {
    transition: 700ms ease-out;
    opacity: 0;
    z-index: -3;
    p {
      opacity: 0;
    }
  }

  ${media.laptop`
    animation: ${PreloaderMove} 2s ease-in-out forwards;
    animation-delay: 3.5s;

    img  {
      position: fixed;
      height: 40px;
      top: calc(var(--vh, 1vh) * 49);
      left: 40vw;
      animation: ${LogoMove} 1.25s ease-in-out forwards;
      animation-delay: 3.5s;
    }

    .red {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      background: var(--light-red);
      animation: ${RedSlide} 4s ease-in-out forwards;
    }

    .green {
      position: absolute;
      top: 0;
      left: 100vw;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      background: var(--light-green);
      animation: ${GreenSlide} 4s ease-in-out forwards;
    }

    .blue {
      position: absolute;
      top: 0;
      left: 200vw;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      background: var(--light-blue);
      animation: ${BlueSlide} 4s ease-in-out forwards;
    }
  `}
`

function Loader(props) {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  return (
    <LoaderCon className={props.loaded ? "disable" : null}>
      <div className="red"></div>
      <div className="green"></div>
      <div className="blue"></div>
      <img src={Logo} alt="logo" />
    </LoaderCon>
  );
}

export default Loader;