import React, { useState } from "react"
import styled from 'styled-components'
import media from "../../styles/media"
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import Modal from 'react-modal'

import ArrowLeft from '../../images/arrow-back.svg'
import ArrowRight from '../../images/arrow.svg'

const WorkCarouselCon = styled.div`
  margin-top: 50px;

  .showing {
    display: block;
  }
  .hidden {
    display: none;
  }

  .swiper-container {
    overflow: visible;
    .swiper-button-prev {
      position: absolute;
      margin-top: 100px;
      margin-left: 60vw;
      color: var(--dark-red);
      background-image:  url(${ArrowLeft});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .swiper-button-next {
      position: absolute;
      margin-top: 100px;
      color: var(--dark-red);
      background-image:  url(${ArrowRight});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .swiper-button-next::after {
      display: none;
    }
    .swiper-button-prev::after {
      display: none;
    }
    .swiper-wrapper {
      .swiper-slide {
        button {
          background: transparent;
          border: none;
          cursor: pointer;
        }
        img  {
          width:75vw;
          height: 205px;
        }
        iframe {
          width: 100%;
          height: 205px;
        }
      }
    }
  }

  .desk {
    display: none;
  }

  .mob {
    overflow: hidden;
    margin: 0 20px 150px 20px;
    .swiper-container {
      height:270px;
    }
  }

  ${media.laptop`
    margin: 100px 0px 250px 70px;

    .desk {
      display: block;
    }
    .mob{
      display: none;
    }

    .swiper-container {
      overflow: visible;
      height: 470px;
      .swiper-button-prev {
        position: absolute;
        right: 180px;
        left: auto;
        margin-top: 300px;
        color: var(--dark-red);
        background-image:  url(${ArrowLeft});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .swiper-button-next {
        position: absolute;
        right: 120px;
        margin-top: 300px;
        color: var(--dark-red);
        background-image:  url(${ArrowRight});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .swiper-button-next::after {
        display: none;
      }
      .swiper-button-prev::after {
        display: none;
      }
      .swiper-wrapper {
        .swiper-slide {
          overflow: hidden;
          width:50vw;
          height: 470px;
           button {
             width: 100%;
           }          
          img  {
            object-fit: cover;
            width: 100%;
            min-height: 470px;
            height: auto;
          }
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  `}
`

function WorkCarousel(info) {
  const [modalIsOpen,setIsOpen] = useState(false);
  const [modalImg, setModalImg] = useState('');

  function openModal(imageUrl) {
    setIsOpen(true);
    setModalImg(imageUrl)
  }

  function closeModal(){
    setIsOpen(false);
  }

  SwiperCore.use([Navigation]);

  return (
    <WorkCarouselCon>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <button className="modalButton" onClick={closeModal}>&times;</button>
        <img className="modalImage" src={modalImg} alt="enlarged" />
      </Modal>

      <div className="desk">
       <Swiper
          slidesPerView='auto'
          spaceBetween={100}
          navigation
          loop={false}
          centeredSlides={true}
        > 
          {info.info.data.galleryimage1.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage1.url)}>
                <img src={info.info.data.galleryimage1.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage2.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage2.url)}>
                <img src={info.info.data.galleryimage2.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage3.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage3.url)}>
                <img src={info.info.data.galleryimage3.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage4.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage4.url)}>
                <img src={info.info.data.galleryimage4.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage5.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage5.url)}>
                <img src={info.info.data.galleryimage5.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage6.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage6.url)}>
                <img src={info.info.data.galleryimage6.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage7.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage7.url)}>
                <img src={info.info.data.galleryimage7.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage8.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage8.url)}>
                <img src={info.info.data.galleryimage8.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage9.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage9.url)}>
                <img src={info.info.data.galleryimage9.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage10.url  && (
            <SwiperSlide>
            <button onClick={() => openModal(info.info.data.galleryimage10.url)}>
              <img src={info.info.data.galleryimage10.url} alt="gallery" />
            </button>
            </SwiperSlide>
          )}
        </Swiper>
        </div>
        <div className="mob">
       <Swiper
          slidesPerView={1.15}
          loop={false}
          navigation
        > 
          {info.info.data.galleryimage1.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage1.url)}>
                <img src={info.info.data.galleryimage1.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage2.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage2.url)}>
                <img src={info.info.data.galleryimage2.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage3.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage3.url)}>
                <img src={info.info.data.galleryimage3.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage4.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage4.url)}>
                <img src={info.info.data.galleryimage4.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage5.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage5.url)}>
                <img src={info.info.data.galleryimage5.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage6.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage6.url)}>
                <img src={info.info.data.galleryimage6.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage7.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage7.url)}>
                <img src={info.info.data.galleryimage7.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage8.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage8.url)}>
                <img src={info.info.data.galleryimage8.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage9.url  && (
            <SwiperSlide>
              <button onClick={() => openModal(info.info.data.galleryimage9.url)}>
                <img src={info.info.data.galleryimage9.url} alt="gallery" />
              </button>
            </SwiperSlide>
          )}
          {info.info.data.galleryimage10.url  && (
            <SwiperSlide>
            <button onClick={() => openModal(info.info.data.galleryimage10.url)}>
              <img src={info.info.data.galleryimage10.url} alt="gallery" />
            </button>
            </SwiperSlide>
          )}
        </Swiper>
        </div>
    </WorkCarouselCon>
  );
}
export default WorkCarousel;