import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import styled from 'styled-components'
import media from '../styles/media'
import { useLocation } from 'react-router-dom'

import Logo from '../images/logo.png'

const HeadNav = styled.nav`
  max-width: 100vw;
  width: 89%;
  z-index: 998;
  display: flex;
  justify-content: space-between;
  padding: var(--gutter-mobile-s);
  background-color: transparent;
  border: none;
  position: absolute;

  a {
    color: white;
    text-decoration: none;
  }

  .items {
    display: none;
  }

  .line,
  .box {
    transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .menuIcon {
    border: none;
    background: transparent;
    &:active {
      outline: none;
    }
  }

  .line {
    height: 2px;
    width: 11px;
    background: #fff;
    margin: 4px auto 0 0;
  }

  .menu__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .menu__wrapper > div {
    width: 20px;
    height: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .menu__wrapper > div:hover, .menu__wrapper > div:focus {
    outline: none;
  }
  .menu__wrapper > span {
    display: inline-block;
    text-align: center;
    line-height: 1.2;
    padding: 20px;
    margin-top: auto;
  }

  .menu__item--doner {
    flex-direction: column;
  }
  .menu__item--doner .line:nth-child(2) {
    width: 25px;
  }
  .menu__item--doner .line:nth-child(3) {
    width: 19px;
  }

  .menuIcon  {
    &.open {
      .menu__item--doner .line:nth-child(1) {
        transform: rotate(45deg) translate(3px, 6px);
        width: 20px;
      }
      .menu__item--doner .line:nth-child(2) {
        width: 20px;
        transform: rotate(-45deg) translate(-1px, -1.2px);
      }
      .menu__item--doner .line:nth-child(3) {
        transform: rotate(-45deg) translate(4px, -5px);
      }
    }
  }

  img {
    height: 28px;
  }

  ${media.laptop`
    padding: var(--gutter-s) var(--gutter-m);
    width: 92vw;

    .items {
      display: block;
      align-self: center;
      a {
        font-size: 18px;
        margin-left: var(--gutter-head);
        background-image: linear-gradient(white, white), linear-gradient(transparent, transparent);
        background-size: 0 2px, auto;
        background-repeat: no-repeat;
        background-position: left bottom;
        transition: all .3s ease-out;
        &:hover {
          color: white;
          text-decoration: none;
          /* background-size: 100% 2px, auto; */
        }
      }
    }

    .menuIcon {
      display: none;
    }

    img {
      height: 40px;
    }
    
    .menu__link {
      display: inline-block;
      text-decoration: none;
      position: relative;
      padding: 14px 0;
    }

    .menu__link {

      overflow: visible;
      svg {
        fill: none;
        stroke: var(--color);
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke-dasharray: 338;
        stroke-dashoffset: 338;
        stroke-linecap: round;
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% + 60px);
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: stroke-dashoffset 0s 0.2s, opacity 0.2s;
        z-index: -1;
        overflow: visible;
      }
    }

    .menu__link:hover svg {
      stroke-dashoffset: 0;
      opacity: 1;
      transition: opacity 0s, stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  `}
`

const MenuCon = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: var(--dark-green);
  color: white;
  left: -100vw;
  top: 0;
  transition: all 0.5s linear;
  z-index: 997;
  a {
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    margin: 20px 0 0;
    display: block;
  }

  .items {
    margin: 20vh 0;
  }

  &.open {
    left: 0;
  }
`

const Header = () => {
  const [ open, setOpen ] = useState(false)
  const location = useLocation();


  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  if (location.pathname.replace("/","") === 'about')  {
    document.documentElement.style.setProperty('--color', `var(--dark-red)`);
  } else if (location.pathname.replace("/","") === 'services') {
    document.documentElement.style.setProperty('--color', `var(--dark-green)`);
  } else if (location.pathname.includes('blog') && location.pathname.length < 6) {
    document.documentElement.style.setProperty('--color', `var(--dark-red)`);
  } else if (location.pathname.includes('blog') && location.pathname.length > 6) {
    document.documentElement.style.setProperty('--color', `var(--dark-green)`);
  } else if (location.pathname.includes('work') && location.pathname.length < 6) {
    document.documentElement.style.setProperty('--color', `var(--dark-blue)`);
  } else if (location.pathname.includes('work') && location.pathname.length > 6) {
    document.documentElement.style.setProperty('--color', `var(--dark-red)`)
  } else if (location.pathname.replace("/","") === 'contact') {
    document.documentElement.style.setProperty('--color', `var(--dark-yellow)`);
  } else {
    document.documentElement.style.setProperty('--color', `var(--dark-blue)`);
  }


  return (
    <>
    <HeadNav>
      <NavLink exact activeClassName="menu__link--active" to="/" className="menu__link">
        <img src={Logo} alt="logo"  />
      </NavLink>
      <button className={open ? 'menuIcon open' : 'menuIcon'} onClick={() => setOpen(!open)}>
        <div className="menu__wrapper">
          <div className="menu__item--doner" tabIndex="2">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </button>
      <div className="items">
        <NavLink activeClassName="menu__link--active" to="/about" className="menu__link">
          About
          <svg width="109" height="47" viewBox="0 0 109 47" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M108.5 23.5C108.5 26.5767 107.056 29.5488 104.36 32.2971C101.662 35.0477 97.7334 37.5476 92.8393 39.6579C83.053 43.8777 69.4983 46.5 54.5 46.5C39.5017 46.5 25.947 43.8777 16.1607 39.6579C11.2666 37.5476 7.33788 35.0477 4.63982 32.2971C1.94395 29.5488 0.5 26.5767 0.5 23.5C0.5 20.4233 1.94395 17.4512 4.63982 14.7029C7.33788 11.9523 11.2666 9.4524 16.1607 7.34213C25.947 3.12231 39.5017 0.5 54.5 0.5C69.4983 0.5 83.053 3.12231 92.8393 7.34213C97.7334 9.4524 101.662 11.9523 104.36 14.7029C107.056 17.4512 108.5 20.4233 108.5 23.5Z"/>
          </svg>
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/services" className="menu__link">
          Services
          <svg width="109" height="47" viewBox="0 0 109 47" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M108.5 23.5C108.5 26.5767 107.056 29.5488 104.36 32.2971C101.662 35.0477 97.7334 37.5476 92.8393 39.6579C83.053 43.8777 69.4983 46.5 54.5 46.5C39.5017 46.5 25.947 43.8777 16.1607 39.6579C11.2666 37.5476 7.33788 35.0477 4.63982 32.2971C1.94395 29.5488 0.5 26.5767 0.5 23.5C0.5 20.4233 1.94395 17.4512 4.63982 14.7029C7.33788 11.9523 11.2666 9.4524 16.1607 7.34213C25.947 3.12231 39.5017 0.5 54.5 0.5C69.4983 0.5 83.053 3.12231 92.8393 7.34213C97.7334 9.4524 101.662 11.9523 104.36 14.7029C107.056 17.4512 108.5 20.4233 108.5 23.5Z"/>
          </svg>
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/work" className="menu__link">
          Work
          <svg width="109" height="47" viewBox="0 0 109 47" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M108.5 23.5C108.5 26.5767 107.056 29.5488 104.36 32.2971C101.662 35.0477 97.7334 37.5476 92.8393 39.6579C83.053 43.8777 69.4983 46.5 54.5 46.5C39.5017 46.5 25.947 43.8777 16.1607 39.6579C11.2666 37.5476 7.33788 35.0477 4.63982 32.2971C1.94395 29.5488 0.5 26.5767 0.5 23.5C0.5 20.4233 1.94395 17.4512 4.63982 14.7029C7.33788 11.9523 11.2666 9.4524 16.1607 7.34213C25.947 3.12231 39.5017 0.5 54.5 0.5C69.4983 0.5 83.053 3.12231 92.8393 7.34213C97.7334 9.4524 101.662 11.9523 104.36 14.7029C107.056 17.4512 108.5 20.4233 108.5 23.5Z"/>
          </svg>
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/blog" className="menu__link">
          Blog
          <svg width="109" height="47" viewBox="0 0 109 47" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M108.5 23.5C108.5 26.5767 107.056 29.5488 104.36 32.2971C101.662 35.0477 97.7334 37.5476 92.8393 39.6579C83.053 43.8777 69.4983 46.5 54.5 46.5C39.5017 46.5 25.947 43.8777 16.1607 39.6579C11.2666 37.5476 7.33788 35.0477 4.63982 32.2971C1.94395 29.5488 0.5 26.5767 0.5 23.5C0.5 20.4233 1.94395 17.4512 4.63982 14.7029C7.33788 11.9523 11.2666 9.4524 16.1607 7.34213C25.947 3.12231 39.5017 0.5 54.5 0.5C69.4983 0.5 83.053 3.12231 92.8393 7.34213C97.7334 9.4524 101.662 11.9523 104.36 14.7029C107.056 17.4512 108.5 20.4233 108.5 23.5Z"/>
          </svg>
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/contact" className="menu__link">
          Contact
          <svg width="109" height="47" viewBox="0 0 109 47" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M108.5 23.5C108.5 26.5767 107.056 29.5488 104.36 32.2971C101.662 35.0477 97.7334 37.5476 92.8393 39.6579C83.053 43.8777 69.4983 46.5 54.5 46.5C39.5017 46.5 25.947 43.8777 16.1607 39.6579C11.2666 37.5476 7.33788 35.0477 4.63982 32.2971C1.94395 29.5488 0.5 26.5767 0.5 23.5C0.5 20.4233 1.94395 17.4512 4.63982 14.7029C7.33788 11.9523 11.2666 9.4524 16.1607 7.34213C25.947 3.12231 39.5017 0.5 54.5 0.5C69.4983 0.5 83.053 3.12231 92.8393 7.34213C97.7334 9.4524 101.662 11.9523 104.36 14.7029C107.056 17.4512 108.5 20.4233 108.5 23.5Z"/>
          </svg>
        </NavLink>
      </div>
    </HeadNav>

    <MenuCon className={open ? 'open' : ''}>
      <div className="items">
        <NavLink exact activeClassName="menu__link--active" to="/" onClick={() => setOpen(!open)}>
          Home
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/about" onClick={() => setOpen(!open)}>
          About
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/services" onClick={() => setOpen(!open)}>
          Services
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/work" onClick={() => setOpen(!open)}>
          Work
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/blog" onClick={() => setOpen(!open)}>
          Blog
        </NavLink>
        <NavLink activeClassName="menu__link--active" to="/contact" onClick={() => setOpen(!open)}>
          Contact
        </NavLink>
      </div>
    </MenuCon>
    </>
  );
}
export default Header;