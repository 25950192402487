import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'

import BG from '../../images/value-bg.png'
import BGMob from '../../images/value-bg-mob.png'

const ValuesCon = styled.div`
  padding: 25px 20px;
  
  p {
    text-transform: uppercase;
    color: var(--light-red);
  }
  h2 {
    color: var(--dark-red);
    font-weight: normal;
  }

  .values {
    .top {
      height: 170px;
      background-color: white;
      background-image:  url(${BGMob});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;
      h3 {
        font-weight: normal;
        color: white;
        font-size: 24px;
        line-height: 29px;
        padding: 80px 20px 0;
      }
      img {
        height: 195px;
        margin-left: calc(65vw - 40px);
      }
    }
    .bottom {
      height: 250px;
      background-color: white;
      margin-bottom: 50px;
      margin-top: -20px;
      p {
        padding: 40px 20px 0;
        color: #909090;
        text-transform: none;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  ${media.laptop`
    padding: 10vh 60px 15vh;

    .values {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 60px;

      overflow: hidden;
      .top {
        max-width: 28vw;
        background-image:  url(${BG});
        height: 230px;
        h3 {
          font-weight: normal;
          font-size: 30px;
          line-height: 36px;
          padding: 140px 20px 0;
          align-self: flex-end;
        }
        img {
          width: 200px;
          height: auto;
          margin: 0 0 0 14vw;
        }
      }
      .bottom {
        height: 330px;
        margin-bottom: 0;
        p {
          padding: 60px 20px 0;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  `}
`

function Values() {
  return (
    <ValuesCon>
      <p className="subHeading">values</p>
      <h2 className="headingTextL">Who we are as Catalysts</h2>
      <div className="values">
        <div className="block">
          <div className="top">
            {/* <img src={Logo} alt="logo" /> */}
            <h3>We are creators</h3>
          </div>
          <div className="bottom">
            <p>
            The catalysts of connections and the
            accelerators of growth. Our experience
            and curiosity make us think beyond the
            norm, finding ground-breaking solutions
            that focus on results.
            </p>
          </div>
        </div>
        <div className="block">
          <div className="top">
            {/* <img src={Logo} alt="logo" /> */}
            <h3>Africa is home</h3>
          </div>
          <div className="bottom">
            <p>
            We understand the land, the people, the
            environment and the unique challenges
            that African businesses face.
            </p>
          </div>
        </div>
        <div className="block">
          <div className="top">
            {/* <img src={Logo} alt="logo" /> */}
            <h3>Above all, we care</h3>
          </div>
          <div className="bottom">
            <p>
            We’re not just a marketing agency, but
            rather collaborators in your success.
            As your strategic partner, we share our
            knowledge and experience with passion
            and purpose. With us, it’s personal.
            </p>
          </div>
        </div>
      </div>
    </ValuesCon>
  );
}
export default Values;