import React from "react"
import styled from 'styled-components'
import Footer from '../footer'

import Fb from '../../images/social/fb-yellow.png'
import Li from '../../images/social/li-yellow.png'
import media from "../../styles/media"

const DetailsCon = styled.div`
  padding: 50px 20px 150px;
  p {
    color: #909090;
    width: auto;
    &.send {
      font-size: 30px;
      line-height: 36px;
      color: var(--dark-yellow);
    }
  }
  .contactDetails {
    display: grid;
    a {
      color: #909090;
      text-decoration: none;
      font-size: 18px;
      line-height:22px;
      margin: 20px 0;
      width: fit-content;
      &.address {
        margin: 60px 0;
      }
      &.lined {
        border-bottom: 2px solid white;
      }
    }
    .social {
      margin: 60px 0 0;
      img {
        height: 30px;
      }
      a {
        margin: 0 40px 0 0;
      }
    }
  }

  ${media.laptop`
    padding: 15vh 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    p {
      width: 400px;
      &.send {
        font-size: 40px;
        line-height: 48px;
        /* color: #909090; */
      }
    }
    .contactDetails {
      margin-top: -20px;
      a {
        font-size: 30px;
        line-height: 36px;
      }
      .social {
        margin: 60px 0 0;
        img {
          height: 50px;
        }
        a {
          margin: 0 40px 0 0;
        }
      }
    }
  `}
`

function Details() {
  return (
    <>
    <DetailsCon>
      <p className="send">Send us your questions, projects, or just say hi.</p>
      <div className="contactDetails">
        <a href="https://goo.gl/maps/GWMfKoxmprzTXiPc9" target="_blank" rel="noreferrer" className="address">Building 14, The Woodlands Office Park, Woodlands Drive, Woodmead, Johannesburg, South Africa</a>
        <a href="mailto:hello@thecatalyst.africa" className="lined">hello@thecatalyst.africa</a>
        <a href="tel:+27110655000" className="">+27 (0) 11 065 5000</a>
        <div className="social">
          <a href="https://www.facebook.com/TheCatalystAfrica/" target="_blank" rel="noreferrer"><img src={Fb} alt="fb" /></a>
          <a href="https://www.linkedin.com/company/catalyst-africa/" target="_blank" rel="noreferrer"><img src={Li} alt="li" /></a>
        </div>
      </div>
    </DetailsCon>
    <Footer color={'yellow'} />
    </>
  );
}
export default Details;