import React from "react";
import styled from 'styled-components'
import media from "../styles/media";
import { Link } from 'react-router-dom'

const FooterCon = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;

  ${media.laptop`
    margin: 0 60px;
  `}
`

const CopyText = styled.p`
  margin-top: -60px;
  text-transform: uppercase;
  font-size: 10px;
  button {
    border: none;
    background: none;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    font-size: 10px;

    &.white {
      color: white;
    }
    &.grey {
      color: #C4C4C4;
    }
    &.red {
      color: var(--dark-red);
    }
    &.blue {
      color: var(--dark-blue);
    }
    &.green {
      color: var(--dark-green);
    }
    &.yellow {
      color: var(--dark-yellow);
    }
  }
  &.white {
    color: white;

    a {
    color: white;
    }
  }
  &.grey {
    color: #C4C4C4;
    a {
    color: #C4C4C4;
    }
  }
  &.red {
    color: var(--dark-red);
    a {
    color: var(--dark-red);
    }
  }
  &.blue {
    color: var(--dark-blue);
    a {
    color: var(--dark-blue);
    }
  }
  &.green {
    color: var(--dark-green);
    a {
    color: var(--dark-green);
    }
  }
  &.yellow {
    color: var(--dark-yellow);
    a {
    color: var(--dark-yellow);
    }
  }

  ${media.laptop`
  font-size: 16px;
  button {
    font-size: 16px;
  }
  `}
`

function Footer( { color }) {

  const moveUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (
    <FooterCon>
    <CopyText className={color}>2021 &#169;	The Catalyst Africa / <Link to="/privacy">PRIVACY POLICY</Link> </CopyText>
    <CopyText className={color}><button className={color} onClick={() => moveUp()}>Back to the top</button></CopyText>
    </FooterCon>
  );
}

export default Footer;