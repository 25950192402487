import React from "react";
import styled from 'styled-components'
import media from "../styles/media";
import { Link } from 'react-router-dom'

import Logo from '../images/blue-logo.png'
import Arrow from '../images/arrow-back.svg'

const Page404Con = styled.div`
  max-width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: var(--dark-blue);
  font-size: 40px;
  line-height: 48px;
  padding: 0 20px;
  color: white;
  display: inline-block;
  display: grid;
  grid-template-rows: 0.75fr 1fr;

  .fourhead {
    color: var(--light-blue);
    z-index: 2;
    align-self: end;
    margin: 0;
  }
  .back {
    z-index: 2;
    display: flex;
    p {
      margin: 0;
    }
    a {
      background: var(--light-red);
      color: var(--dark-red);
      border-radius: 50%;
      width: 50px;
      height: 35px;
      text-decoration: none;
      display: flex;
      justify-content: center;
        margin: 10px 10px 0 0px;
      img {
        width: 20px;
        max-height: 30px;
        align-self: center;
      }
    }
  }

  .logo {
    position: absolute;
    right: 0;
    bottom: calc(var(--vh, 1vh) * 10);
    width: 50vw;
    z-index: 1;;
  }

  ${media.laptop`
    font-size: 90px;
    line-height: 80px;
    padding: 0 40px;
    grid-template-rows: 1fr 1fr;

    .logo {
      bottom: auto;
      top: calc(var(--vh, 1vh) * 10);
      width: auto;
      height: 80vh;
    }
    .back {
      margin-top: 20px;
      a {
        width: 55px;
        height: 55px;
        margin: 20px 20px 0 0px;
        img {
          width:  40px;
        }
      }
    }
  `}
`

function Page404() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  return (
    <Page404Con>
      <p className="fourhead">Page Not Found</p>
      <div className="back">
        <Link to="/"> <img src={Arrow} alt="arrow" /> </Link>
        <p className="backtext">Go back to the home page</p>
      </div>

      <img className="logo" src={Logo} alt="pattern"/>
    </Page404Con>
  );
}

export default Page404;
