import React from "react";
import styled from 'styled-components'
import media from "../styles/media";
import { useLocation } from 'react-router-dom'

const BackgroundCon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transition:all 0.3s linear;
  z-index:-1;
  height: calc(var(--vh, 1vh) * 80);
  width: 100vw;

  &.transparent {
    background-color: transparent;
  }
  &.red {
    background-color: var(--light-red);
  }
  &.blue {
    background-color: var(--light-blue);
  }
  &.green {
    background-color: var(--light-green);
  }
  &.darkGreen {
    background-color: var(--dark-green);
  }
  &.yellow {
    background-color: var(--light-yellow);
  }

  ${media.laptop`
    height: 95vh;
    width: 100vw;
  `}
`

function Background() {
  const location = useLocation();
  let bgColor = 'transparent'

  if (location.pathname.replace("/","") === 'about')  {
    bgColor = 'red'
  } else if (location.pathname.replace("/","") === 'services') {
    bgColor = 'green'
  } else if (location.pathname.includes('blog') && location.pathname.length < 6) {
    bgColor = 'red'
  } else if (location.pathname.includes('blog') && location.pathname.length > 6) {
    bgColor = 'green'
  } else if (location.pathname.includes('work') && location.pathname.length < 6) {
    bgColor = 'blue'
  } else if (location.pathname.includes('work') && location.pathname.length > 6) {
    bgColor = 'red'
  } else if (location.pathname.replace("/","") === 'contact') {
    bgColor = 'yellow'
  } else if (location.pathname.includes("privacy")) {
    bgColor = 'darkGreen'
  } else {
    bgColor = 'transparent'
  }

  return (
    <BackgroundCon className={bgColor}>
    </BackgroundCon>
  );
}

export default Background;
