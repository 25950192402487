import React from "react"
import styled from 'styled-components'
import media from "../../styles/media";

const TemplateHeroCon = styled.div`
  height: 450px;
  width: 100vw;

  /* .author {
    position: absolute;
    top: 300px;
    left: 20px;
    color: var(--dark-red);
    margin: 15px 0;
    text-transform: uppercase;
  } */
  .link {
    position: absolute;
    top: 300px;
    left: 20px;
    color: var(--dark-red);
    text-transform: uppercase;
    margin: 15px 0;
  }
  .title {
    color: white;
    width: 80vw;
    position: absolute;
    left: 20px;
    top: 220px;
  }

  .blurb {
    color: var(--dark-red);
    width: calc(100vw - 40px);
    position: absolute;
    left: 20px;
    top: 100px;
  }

  ${media.laptop`
    height: 95vh;
    .link {
      position: absolute;
      top: 70vh;
      left: 60px;
    }

    .title {
      color: white;
      width: 55vw;
      left: 60px;
      position: absolute;
      top: 35vh;
    }

    .blurb {
      color: var(--dark-red);
      width: 600px;
      position: absolute;
      left: 60px;
      top: 45vh;
    }
  `}
`

function TemplateHero(info) {
  
  return (
    <TemplateHeroCon>
      <p className="title blurbText">{info.info.data.title[0].text}</p>
      <p className="blurb blurbText">{info.info.data.description[0].text}</p>
      {/* {info.info.data.services[0] && (
        <p className="author subHeading">{info.info.data.services[0].text}</p>
      )} */}
      {info.info.data.websitelink.url && (
        <a href={info.info.data.websitelink.url} target="_blank" rel="noreferrer" className="link subHeading">Visit website</a>
      )}
    </TemplateHeroCon>
  );
}
export default TemplateHero;