import React, { useEffect } from "react"
import styled from 'styled-components'
import media from "../../styles/media";
import Footer from "../footer";
import TemplateHero from "./templateHero";
import TemplateThumbnail from "./templateThumbnail";
import { useLocation } from 'react-router-dom'
import WorkCarousel from "./carousle";

const BodyCon = styled.div`
  margin: 50px 20px 100px; 
  
  h2 {
    margin: 20px 0 30px;
  }

  .results {
    text-align: center;

    .heading3 {
      margin: 50px auto 0;
    }
    .paragraph {
      margin: 0 auto 50px;
    }
  }
  
  ul {
    margin: 0 0 0 -15px;
    li {
      color: var(--dark-red);
      span {
        color: var(--dark-red);
        font-weight: bold;
      }
    }
  }

  .watch {
    color: var(--light-red);
    text-transform: uppercase;
    text-decoration: none;
  }

  iframe {
    width: 100%;
    height: 250px;
  }

  ${media.laptop`
    margin: 100px 350px 150px;

    .results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 0.2fr;

      .heading3 {
        margin: 10px 0 0;
        &:nth-child(1) {
          grid-row: 1;
          grid-column: 1;
        }
        &:nth-child(3) {
          grid-row: 1;
          grid-column: 2;
        }
        &:nth-child(5) {
          grid-row: 1;
          grid-column: 3;
        }
        &:nth-child(7) {
          grid-row: 3;
          grid-column: 1;
        }
        &:nth-child(9) {
          grid-row: 3;
          grid-column: 2;
        }
        &:nth-child(11) {
          grid-row: 3;
          grid-column: 3;
        }
        &:nth-child(13) {
          grid-row: 5;
          grid-column: 1;
        }
      }
      .paragraph {
        text-align: center;
        margin: 0 0 20px;
        &:nth-child(2) {
          grid-row: 2;
          grid-column: 1;
        }
        &:nth-child(4) {
          grid-row: 2;
          grid-column: 2;
        }
        &:nth-child(6) {
          grid-row: 2;
          grid-column: 3;
        }
        &:nth-child(8) {
          grid-row:4;
          grid-column: 1;
        }
        &:nth-child(10) {
          grid-row: 4;
          grid-column: 2;
        }
        &:nth-child(12) {
          grid-row: 4;
          grid-column: 3;
        }
        &:nth-child(14) {
          grid-row: 6;
          grid-column: 3;
        }
      }
    }

    iframe {
      height: 400px;
    }
  `}
`

function CaseStudy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  let location = useLocation()

  var caseStudy = JSON.parse(localStorage.getItem("casestudy"))

  return (
    <>
    <TemplateHero info={caseStudy} />
    <TemplateThumbnail info={caseStudy} />
    <BodyCon>
      <p className="bodyText">
        {caseStudy.data.introduction[0].text}
      </p>

      <br/><br/>

      {caseStudy.data.videolink.url && (
        <>
          {/* <a className="watch bodyText" href={caseStudy.data.videolink.url} target="_blank" rel="noreferrer">Watch the video here</a> */}
          <iframe
            src={caseStudy.data.videolink.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          <br/><br/>
        </>
      )}

      <h2 className="blurbText">The Result</h2>
      {caseStudy.data.result[0].type === 'heading3'  && (
        <div className="results">
          {caseStudy.data.result.map((item, index) => {return(<p key={index} className={item.type}>{item.text}</p>)})}
        </div>
      )}
      {caseStudy.data.result[0].type !== "heading3"  && (
        <ul>
          {caseStudy.data.result.map((item, index) => {return(<li key={index} className={item.type}><span>{item.text}</span></li>)})}
        </ul>
      )}

      <br/><br/>

      <h2 className="blurbText">The Insight</h2>
      {caseStudy.data.insight.map((item, index) => {return(<p key={index} className={item.type}>{item.text}</p>)})}

      <br/><br/>

      <h2 className="blurbText">The Strategy</h2>
      {caseStudy.data.strategy.map((item, index) => {return(<p key={index} className={item.type}>{item.text}</p>)})}

      <br/><br/>

    </BodyCon>

    <WorkCarousel info={caseStudy} />

    <Footer color="grey" info={caseStudy} />
    </>
  );
}
export default CaseStudy;